.bg-table {
    margin-bottom: 100px;
    height: calc(70vh - 40px);
    overflow-x: auto;
    /* Agrega un desplazamiento vertical si es necesario */
    /*height: 100vh;
    overflow-y: auto;*/
}

.iconButton {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.tdTable {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #000;
    text-align: start;
}

.tdExtraCuentas {
    font-size: 18px;
}

.trHead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.saldo {
    color: #4184EC;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    text-align: end;
}

.trStatus td {
    color: red;
}

.trStatusG td {
    color: green;
}

/*['', '', '','','','amountStyle','']*/
.dateStyle {}

.amountStyle {
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    text-align: end;
}

.accountStyle {
    text-align: center;
}

.textStyle {
    text-align: start;
}

.centeredStyle {
    text-align: center;
}

.centrado {
    text-align: center;
}

table {
    width: 100%;
    background: #fff;
    border-collapse: collapse;
}

.trh {
    width: 14%;
}

/* Cambiar el color de fondo de los encabezados de la tabla */
th {
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    background-color: #D9E6FB;
    /* 818BBC 3E478C 4D58A2 A4ACD0 EBF1FC  Color de fondo verde oscuro, puedes cambiarlo según tus preferencias */
    color: #000;
    /* fff 000 2B2C68 373D80  Cambiar el color del texto para que sea legible en el fondo */
    text-align: center;
    /* Centra el contenido horizontalmente */
    vertical-align: middle;
    /* Centra el contenido verticalmente */
}

td {
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    /* Centra el contenido horizontalmente */
    vertical-align: middle;
    /* Centra el contenido verticalmente */
    color: #000;
}

.content-paginate {
    padding-left: 5px;
    margin-top: -25px;
}

.trtable:hover {
    background: #234C9020;
}

.trtableCard {
    border-bottom: 1px solid gray;
}

.trtableCard:hover {
    background: #234C9020;
}

.activetrCard {
    background: #4184EC50;
    border-bottom: 1px solid gray;
}

.activetr {
    background: #4184EC50;
}

.card-table {
    padding: 10px;
    border: 1px solid #4184EC;
    border-radius: 16px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.card-header-table {
    border-bottom: 1px solid #4184EC;
    padding-left: 10px;
    padding-right: 10px;
}

.header-table {
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 18px;
}

.card-body-table {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    text-align: right;
}

.space-table {
    margin-left: 15px;
    padding: 0px;
}

.text-table {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    font-size: 16px;
    margin-bottom: 5px;
}

.text-table-date {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    font-size: 16px;
    margin-bottom: 5px;
}

.row-table {
    padding-left: 10px;
    padding-right: 10px;
}

.text-table-title {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-bottom: 0px;
}

.text-table-title2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-bottom: 0px;
}

.pagination {
    position: fixed;
    width: auto;
}

.ACTIVE {
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.BLOCKED {
    color: #ffc107;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.DISABLED {
    color: red;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.sub-menu {
    text-decoration: none;
    font-size: 16px;
    color: #000;
}

.item {
    margin-bottom: 5px;
}

.item:hover {
    background: #4184EC50;
}

.subMenu {
    padding-left: 15px;
    padding-right: 15px;
}
.content-header {
    height: 72px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    /* Larger shadow */
    background-color: #f8f9fa;
    /* Background color (assuming bg-body-tertiary is a light color) */
}

.nav-out {
    margin-right: -60px;
}

.navbar {
    width: 100%;
    padding-top: 5px;
    padding-right: 90px;
    justify-content: space-between;
}

.pos-img {
    padding-top: 5px;
    text-align: center;
    width: 80px;
}

.menuP {
    margin-bottom: 10px;
    margin-right: 30px;
}

.menuPerfil {
    margin-left: -100px;
    text-align: right;
}

.btn-group {
    text-align: end;
    margin-left: 40px;
}

.navbar-collapse {
    align-items: end;
    text-align: end;
    justify-content: end;
    font-family: 'Open Sans', sans-serif;
}

.imgmenu {
    width: 0px;
    height: 0px;
    visibility: hidden;
}

a {
    margin-top: 0px;
}

.nav {
    justify-content: end;
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.nav-item {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* Alinea los elementos en una columna */
    align-items: center;
    /* Centra los elementos horizontalmente */
    font-family: 'Open Sans', sans-serif;
}

.imgicon {
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: -3px;
    width: 220px;
}

.iconLogin {
    width: 30px;
    height: 30px;
}

.titlePage {
    color: #4184EC;
    margin-top: 5px;
    font-family: 'Open Sans', sans-serif;
}

ul {
    margin-right: 0px;
}

.nav-link {
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
}

.nav-link2 {
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    margin-left: 1rem;
    margin-right: 1rem;
    text-decoration: none;
}

.nav-item2:hover {
    background-image: url('../../assets/img/LINE_TEXTO.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
}

.active {
    background-image: url('../../assets//img/LINE_TEXTO.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
}

.titlePage2 {
    visibility: hidden;
}

.dropdown-item {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #4184EC;
}

.dropdown-item:hover {
    background: #4184EC20;
    color: #4184EC;
}

.btn-profil {
    width: 40px;
    height: 40px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 120px;
    /* Hace que el botón sea redondo */
    padding: 7px;
    background: #4184EC;
    color: #fff;
    margin-right: 15px;
    margin-bottom: 6px;
}

.navbar-toggler {
    border-color: transparent;
}

.iconP {
    margin-bottom: 22px;
    margin-right: 40px;
}

.navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 10px;
}

.nav-item:hover {
    background: #4184EC20;
    color: #4184EC;
}

.nav-item:active {
    background: #4184EC20;
    color: #4184EC;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #4184EC
}

.nav-link:hover {
    background: #4184EC20;
    color: #4184EC;
}

@media (max-width: 1120px) {
    .nav-link2 {
        font-size: 18px;
    }
}

@media (max-width: 1030px) {
    .nav-link2 {
        font-size: 16px;
    }
}

@media (max-width: 970px) {
    .nav-link2 {
        font-size: 14px;
    }
}

@media (max-width: 920px) {
    .nav-link2 {
        font-size: 12px;
    }
}

@media (max-width: 872px) {
    .nav-link2 {
        font-size: 10px;
    }
}

@media (max-width: 767px) {}

@media (max-width: 767px) {
    .content-header {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 5px;
    }

    .left-shadow {
        margin-left: 20px;
        box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 5px;
    }

    .navbar {
        border-radius: 10px;
        align-items: baseline;
    }

    .menu2 {
        visibility: hidden;
    }

    .btnsp,
    .imgicon,
    .titlePage {
        visibility: hidden;
        width: 0px;
    }

    .imgmenu {
        visibility: visible;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        margin-left: 0px;
        margin-top: -5px;
    }

    .btn-group {
        position: absolute;
    }

    .navbar-nav {
        padding-top: 15px;
        padding-bottom: 15px;
        position: absolute;
        z-index: 1;
        width: 200%;
        margin-top: 50px;
        border-radius: 16px;
        border: 0px, 0px, 1px, 0px;
        background: #fff;
        height: 100vh;
        ;
    }

    .navbar-nav-out {
        background: #fff;
        height: 150px;
        width: 150px;
        margin-left: 10px;
    }

    .pos-img {
        text-align: left;
    }

    .nav-item {
        list-style: none;
        display: flex;
        margin-left: 20px;
        align-items: flex-start;
    }

    .dropdown-menu {
        border-color: #fff;
        align-items: flex-start;
    }

    .navbar-nav-scroll {
        width: fit-content;
        height: fit-content;
    }

    .navbar-toggler {
        margin-top: 10px;
        margin-left: -10px;
    }

    .menuP {
        margin-bottom: -45px;
    }

}

@media (max-width: 815px) {
    .btn-group {
        margin-left: 20px;
    }

    .menuP {
        margin-right: 10px;
    }

}
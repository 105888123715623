
.bodyPagination{
    width: 100%; /* Ocupa todo el ancho de la ventana */
    padding-left: 5px;
}

.pagination{
    margin-top: -45px;
}

.pagination-sm {    
    border-radius: 16px;
    border-color: #4184EC;
}

.page-link{ 
    border-color: transparent;
    margin-right: 10px;
    border-radius: 100%;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: #262626;
}

.active>.page-link, .page-link.active {
    background-color: #4184EC;
    border-color: #4184EC;
}

@media (max-width: 815px){
    .div-paginate{
        margin-top: 120px;
        margin-left: 0px;
    }

    .pagination {
        margin-top: -115px;
        margin-left: 0px;
        margin-right: 15px;
    }

    .bodyPagination{
        margin-left: 0px;
    }
    
}

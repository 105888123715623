.contentBtnSearch{
    margin-right: 15px;
}

.search-head{
    padding-left: 10px;
}

.item-a{
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: #fff;
}

.item-active{
    font-size: 18px;
    text-decoration: none;
    color: #fff;
}

.item-a:active{
    color: #fff;
}

.item-a:hover{
    color: #fff;
}

.btnSearchModal{
    margin-top: 5px;
    margin-right: 10px;
    background: transparent;
    border-color: transparent;
}

.btnMenuTable{
    margin-top: 5px;
    margin-right: 0px;
    background: transparent;
    border-color: transparent;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
}

@media (max-width: 900px){
    .breadcrumb{
        margin: 0px;
        --bs-breadcrumb-item-padding-x: 5px;
    }

    .item-a{
        font-size: 16px;
    }

    .item-active{
        font-size: 16px;
    }
}

@media (max-width: 711px){
    .item-a{
        font-size: 14px;
    }
    
    .item-active{
        font-size: 14px;
    }
}

@media (max-width: 659px){
    .item-a{
        font-size: 12px;
    }
    
    .item-active{
        font-size: 12px;
    }

    .btnSearchModal{
        margin-left: -50px;
        width: 10px;
        margin-top: 10px;
    }

    .imgSearch{
        width: 15px;
    }
}

@media (max-width: 576px){
    .item-a{
        font-size: 10px;
    }
    
    .item-active{
        font-size: 10px;
    }

    .btnSearchModal{
        margin-left: -50px;
        width: 10px;
        margin-top: 10px;
    }

    .imgSearch{
        width: 15px;
    }
}

@media (max-width: 502px){
    .item-a{
        font-size: 8px;
    }
    
    .item-active{
        font-size: 8px;
    }
}

@media (max-width: 540px){
    .item-a{
        font-size: 6px;
    }
    
    .item-active{
        font-size: 6px;
    }
}
.bg-index {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.rowHome {
    margin: 0px;
    padding-top: 8%;
}

.menu-index {
    height: 90px;
    background: #fff;
    border-radius: 80px;
    margin: 2rem;
    border: 1px solid #4184EC;
}

.lineProducts {
    background-image: url('../assets/img/LINE_TEXTO.png');
    background-size: 15rem;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 4px;
    padding-bottom: 45px;
}

.cardsProdc {
    padding-left: 4rem;
    padding-right: 4rem;
}

.cardProducts1 {
    position: relative;
    background: #fff;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 14rem;
    border-radius: 30px;
    border-style: inset;
    border-color: #4184EC;
    box-shadow: 0px 8px 8px 8px rgba(0, 0, 0, 0.5);
}

.cardProducts2 {
    position: relative;
    background: #fff;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 14rem;
    border-radius: 30px;
    border-style: solid;
    border-color: #4184EC;
    box-shadow: 0px 8px 8px 8px rgba(0, 0, 0, 0.5);
}

.cardProducts3 {
    position: relative;
    background: #fff;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    height: 14rem;
    border-radius: 30px;
    border-style: outset;
    border-color: #4184EC;
    box-shadow: 0px 8px 8px 8px rgba(0, 0, 0, 0.5);
}

.lineSolutions {
    background-image: url('../assets/img/LINE_TEXTO.png');
    background-size: 20rem;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 4px;
    padding-bottom: 60px;
    width: 500px;
}

.lineDiscover {
    background-image: url('../assets/img/LINE_TEXTO.png');
    background-size: 19rem;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 145px;
    padding-bottom: 60px;
    width: 450px;
    margin-bottom: 20px;
}

.lineNosotros {
    background-image: url('../assets/img/LINE_TEXTO.png');
    background-size: 20rem;
    background-repeat: no-repeat;
    background-position: right bottom;
    height: 4px;
    padding-bottom: 110px;
    width: 350px;
}

.nosotrosCol1 {
    background: #fff;
    margin-top: -10px;
    margin-left: 3rem;
    margin-right: 3rem;
    height: 12rem;
    border-radius: 30px;
    border-style: inset;
    border-color: #4184EC;
    box-shadow: 0px 8px 8px 8px rgba(0, 0, 0, 0.5);
    padding: 2rem;
}

.nosotrosCol2 {
    background: #fff;
    margin-top: -10px;
    margin-left: 3rem;
    margin-right: 3rem;
    height: 12rem;
    border-radius: 30px;
    border-style: outset;
    border-color: #4184EC;
    box-shadow: 0px 8px 8px 8px rgba(0, 0, 0, 0.5);
    padding: 2rem;
}

.imgComillas {
    height: 40px;
    float: left;
    margin-left: 10%;
    margin-top: -10px;
}

.imgComillas2 {
    height: 40px;
    float: right;
    margin-right: 10%;
    margin-top: -30px;
}

.imgEstrellas {
    height: 35px;
    float: center;
    text-align: center;
    margin-bottom: 1.5rem;
}

.textLoginTitle {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-align: center;
}

.txtNosotros,
.txtNosotros2 {
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

.logo-space {
    text-align: center;
}

.imgNosotros {
    margin-top: 1rem;
    height: 12rem;
}

.bgText {
    margin-left: 40px;
    width: 400px;
    text-align: justify;
    margin-bottom: 30px;
}

.gbProductsImg {
    height: 30vh;
    text-align: center;
}

.bgProduct1 {
    background-image: url('../assets/img/rectangulo3.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 30vh;
    margin-top: 20px;
}

.bgProduct2 {
    background-image: url('../assets/img/rectangulo2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 30vh;
    margin-top: 20px;
}

.bgProduct3 {
    background-image: url('../assets/img/rectangulo1.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 30vh;
    margin-top: 20px;
}

.bgSolutions1 {
    background-image: url('../assets/img/shapeCel.png');
    background-repeat: no-repeat;
    background-size: 60vh;
    background-position: left top;
    height: 70vh;
}

.bgSolutions2 {
    background-image: url('../assets/img/cel2.png');
    background-repeat: no-repeat;
    background-size: 78vh;
    background-position: left center;
    height: 70vh;
}

.bgSolutions3 {
    background-image: url('../assets/img/mundo.png');
    background-repeat: no-repeat;
    background-size: 35vh;
    background-position: center top;
    height: 80vh;
}

.imgProducts {
    height: 30vh;
}

.imgEdificio {
    width: 100vh;
    right: 0px;
    position: absolute;
    margin-top: -350px;
}

.imgGrafica {
    height: 35vh;
    margin-left: 60vh;
    position: absolute;
}

.iconLogo2 {
    height: 40vh;
    position: absolute;
    left: 0px;

}

.body-index {
    margin-top: 0px;
}

.bgTextSolutions1 {
    margin-top: 20px;
    margin-left: 40px;
}

.containerLogin {
    height: 100%;
    width: 100%;
    background: #4184EC;
}

.containerText {
    background: #ffffff99;
    border-bottom-right-radius: 80px;
    border-top-right-radius: 80px;
    padding-left: 7rem;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 7rem;
    margin-top: 3rem;
    border-color: #000;
}

.positionLogo {
    right: 0px;
    position: absolute;
    margin-top: -16rem;
}

.iconLogoSP {
    height: 25vh;
}

.iconSoluctions {
    height: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.space-txt {
    text-align: justify;
    border: none;
    padding-left: 2rem;
}

.subTitleSolutions {
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #4184EC;
    margin-left: 40px;
    margin-bottom: -5px;
}

.subTitleSolutions2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    color: #4184EC;
    margin-left: 40px;
    margin-bottom: -5px;
}

.subTitleSoluction {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    font-weight: bold;
    margin-top: 20px;
    text-align: justify;
}

.text-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    text-align: center;
    margin: 0px;
}

.text-products {
    margin-top: 20px;
    margin-bottom: 20px;
}

.textSolutions1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.txtSolutions {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-align: justify;
}

.containerMenu {
    margin-top: 10px;
}

.titleLogin {
    margin-bottom: 20px;
}

.containerFormLogin {
    background: linear-gradient(to top, #1e2b4e, #577FF1);
    height: auto;
    width: 60%;
    border-radius: 25px;
    margin-left: 20%;
    padding-top: 10px;
    z-index: 0;
}

.containerFormContac {
    background: linear-gradient(to top, #1e2b4e, #577FF1);
    margin-top: 2.5rem;
    height: 44vh;
    width: 58vh;
    border-radius: 20px;
}

.form-label-contact {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #fff;
    padding-bottom: 5px;
    font-weight: bold;
}

.inputFormContact {
    border-radius: 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    width: 100%;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #000;
}

.btnContact {
    background: #fff;
    border-radius: 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    width: 100%;
}

.btnContact:hover {
    background: #e0e0e1;
}

.formContact {
    padding-left: 3rem;
    padding-right: 3rem;
}

.bgformContact {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 15px;
}

.spaceContact {
    margin-top: 10px;
    margin-bottom: 10px;
}

.containerComponet {
    height: auto;
    padding-top: 9%;
    padding-bottom: 13%;
}

.titelLogin2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin: 0px;
}

.textLogin3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
}

.textLogin4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.form-control-login2 {
    -webkit-appearance: none;
    appearance: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    border-radius: 20px;
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    border: 1px solid #4184EC;
}

.bg-inputLogin {
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.btnSecundary {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 5px;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    background: gray;
    color: #fff;
    border: none;
    height: 40px;
}

.btnSecundary:hover {
    color: #000;
    background: #7d7d7d60;
}

.btnLogin2 {
    border-radius: 20px;
    margin-top: 0px;
    width: 100%;
    background: #4184EC;
    color: #fff;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 5px;
}

.btnLogin2:hover {
    background: #4184EC50;
}

.bgBtnLogin {
    margin-left: 25%;
    margin-right: 25%;
}

.txtForget2 {
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    margin: 0px;
    text-decoration: underline;
}

.containerTxtForget {
    padding-bottom: 20px;
}

.space-col-Par {
    margin-top: 4rem;
}

.col-2 {
    height: 150px;
    text-align: center;
}

.col-3 {
    height: 150px;
    border-left: 2px solid #bdbcbc;
    border-right: 2px solid #bdbcbc;
    text-align: center;
}

.col-1 {
    text-align: center;
}

.logosPar1 {
    height: 17%;
}

.logosPar2 {
    height: 80%;
}

.bgLogoP {
    text-align: center;
    margin-top: -35rem;
}

.logoP {
    height: 40vh;
}

.bgSolutions {
    height: 100vh;
    padding-top: 9rem;
    background: #fff;
}

.bgProductos {
    height: 100vh;
    padding-top: 9rem;
    background-image: url('../assets/img/fondoProductos.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bgProductos2 {
    background: #fff;
    padding-top: 9rem;
    height: 100vh;
}

.bgProductos3 {
    padding-top: 9rem;
    height: 100vh;
    background: #fff;
}

.bgPhoneLine {
    background-image: url('../assets/img/flare.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100vh;
    height: 80vh;
    top: -30px
}

.btn-light {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    background: #ffffff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: 1px solid #e0e0e1;
    margin-top: -50px;
}

.btn-light2 {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 500px;
    background: #ffffff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    margin-left: 160px;
    border-radius: 20px;
    border: 1px solid #e0e0e1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.titleIconDes {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
}

.texIconDesc {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 12px;
}

.colDescubre {
    height: 150px;
    width: 60%;
    background: #4184EC;
    position: absolute;
    right: 0px;
    margin-top: -40vh;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.col-icon-des {
    padding-left: 2rem;
    padding-top: 1rem;
}

.iconCheck {
    height: 35px;
    margin-left: 40%;
}

.iconStar {
    height: 30px;
    margin-bottom: 5px;
}

.bgContact {
    height: auto;
}

.bgContactLogin {
    height: 100vh;
    padding-top: 10rem;
    background-image: url('../assets/img/FONDOINICIO.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.bgPhone {
    background-image: url('../assets/img/cel-01.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50vh;
    height: 80vh;
    top: -30px
}

.titleProducts {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: #4184EC;
}

.productsTitle {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #4184EC;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1rem;
}

.textProducts {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    text-align: justify;
}

.cardBody {
    margin: 2rem;
}

.bgContet1 {
    margin-left: 8vh;
    margin-right: 10vh;
    padding-top: 20px;
}

.titleSolucions {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #4184EC;
    padding-left: 40px;
}

.titleDescovery {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #4184EC;
    padding-left: 40px;
    margin-bottom: 0px;
}

.form-control-contact {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    width: 100%;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-color: #7d7d7d;
}

.spaceRow {
    margin: 0px;
    padding: 0px;
}

.colContacto1 {
    height: 100vh;
    background-image: url('../assets/img/bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.colContacto2 {
    background: #4184EC;
    height: 40vh;
}

.cardContact {
    margin-left: 8%;
    margin-right: 8%;
    height: 85vh;
}

.txtContact {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: #4184EC;
    text-align: center;
    padding-left: 40px;
    padding-top: 8%;
}

.textContact {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-left: 5rem;
    margin-right: 5rem;
    text-align: justify;
}

.subtxtHelp {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    font-weight: bold;
}

.titleContact {
    background-image: url('../assets/img/LINE_TEXTO.png');
    background-size: 26rem;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 8px;
}

.foterContact {
    padding: 4rem;
}

.titleFoterContact {
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    color: #fff;
}

.txtFoterContact {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #fff;
    font-weight: 100;
    margin-top: 2rem;
}

.bgIconFoter {
    margin-top: 4rem;
}

.redes {
    width: 90px;
}

.formHelp {
    height: 50vh;
    margin-top: 3rem;
}

.bgHelp {
    padding-left: 5rem;
    padding-right: 5rem;
    margin-top: 2rem;
}

.iconHelp {
    height: 28px;
    margin-right: 10px;
}

.txtHelp {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #000;
}

.txtHelpF {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #fff;
}

.spaceContactF {
    margin-top: 1rem;
}

.colum2 {
    height: 20%;
    background: #4184EC;
}

.container-fluid {
    border-radius: 16px;
    padding: 0px;
    position: relative;
    z-index: 1;
    height: 100%;
    overflow-x: auto;
    top: 0px;
    padding-top: 0px;
    height: calc(100vh - 10px);
}

.section1 {
    background-image: url('../assets/img/ciudad.png');
    /* Reemplaza 'ruta/de/tu/imagen.jpg' con la ruta real de tu imagen */
    background-size: cover;
    /* Ajusta el tamaño de la imagen al tamaño del contenedor */
    background-repeat: no-repeat;
    /* Evita la repetición de la imagen */
    background-position: center center;
    /* Centra la imagen en el contenedor */
    height: calc(90vh - 10px);
}

.section2 {
    background-image: url('../assets/img/Edificios2.png');
    /* Reemplaza 'ruta/de/tu/imagen.jpg' con la ruta real de tu imagen */
    background-size: cover;
    /* Ajusta el tamaño de la imagen al tamaño del contenedor */
    background-repeat: no-repeat;
    /* Evita la repetición de la imagen */
    background-position: center center;
    /* Centra la imagen en el contenedor */
    height: calc(90vh - 10px);
}

.section3 {
    background-image: url('../assets/img/Edificios3.png');
    /* Reemplaza 'ruta/de/tu/imagen.jpg' con la ruta real de tu imagen */
    background-size: cover;
    /* Ajusta el tamaño de la imagen al tamaño del contenedor */
    background-repeat: no-repeat;
    /* Evita la repetición de la imagen */
    background-position: center center;
    /* Centra la imagen en el contenedor */
    height: calc(90vh - 10px);
}

.space-carousel {
    padding-top: 10px;
}

.container-header-carousel {
    padding-top: 4rem;
    height: 80px;
    margin-bottom: 4rem;
}

.logos {
    height: 40px;
    margin-left: 5rem;
    margin-bottom: 20px;
}

.hstack {
    margin-left: 20px;
    height: 40px;
    margin-top: 40px;
}

.container-carousel {
    border-radius: 20px;
    border-style: groove;
    border-color: #4184EC;
    height: 400px;
    margin-left: 4rem;
    margin-right: 4rem;
}

.carousel-indicators {
    margin-bottom: -2rem;
}

.icon-link-casousel {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: rgb(59, 59, 59);
}

.icon-link-casousel:hover {
    color: rgb(24, 24, 24)
}

.icon-link-casousel-active {
    font-size: 22px;
    color: #000000;
    text-decoration: none;
    background: #4184EC50;
    padding: 10px;
}

.imgAzure {
    height: 200px;
    float: right;
    margin-right: 100px;
}

.img-logo-mc {
    height: 100px;
}

.container-img-carousel {
    float: right;
    width: calc(74vh - 10px);
}

.carousel {
    position: absolute;
    padding: 20px;
    margin-right: 4rem;
}

.carousel-inner {
    height: 95%;
}

.carousel-control-next {
    margin-right: -2rem;
}

.carousel-control-prev {
    margin-left: -2rem;
}

.carousel-item {
    margin-left: 4rem;
    margin-right: 4rem;
    display: none;
}

.image-container {
    width: 100%;
}

.space-body-carousel {
    padding-left: 50px;
    padding-right: 50px;
}

.iconEmpresa {
    height: 60%;
}

.col-login {
    z-index: 0;
}

.img-fluid2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 70px;
}

.overlay {
    margin-top: 70px;
    background: #ffffffd1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 5rem;
    z-index: 0;
}

.overlay2 {
    background: #ffffffd1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.button-check {
    height: 25px;
    width: 25px;
    border-radius: 99%;
}


.col-primary {
    height: 60%;
    padding: 10px;
    margin: 0px;
}

.col-succundar {
    height: 40%;
    width: 100%;
}

.bg-primary-p {
    height: 100%;
    padding: 0px;
}

.bg-secondary-p {
    background: #fff;
    height: calc(90% - 15px);
    padding-left: 7rem;
    padding-right: 7rem;
}

.imgImac {
    width: 100%;
}

.imgTerminal {
    width: 50%;
    margin-top: -25%;
}

.bg-text-terminal {
    padding-top: 10px;
}

.col-productos {
    padding: 20px;
}

.col-content-p {
    background: #4184EC;
    border-radius: 16px;
    margin-left: 20px;
    padding: 20px;
    height: 100%;
}

.modal-body {
    z-index: 0;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -2px;
    box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
    margin-bottom: 20px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.container-fluid2 {
    border-radius: 16px;
    padding: 0px;
    position: relative;
    z-index: -1;
    height: 100%;
    top: 0px;
    width: 100%;
    height: calc(100vh - 60px);
    margin-bottom: 100px;
}

.container-body-modal {
    border-radius: 16px;
    padding: 0px;
    position: relative;
    z-index: -1;
    height: 100%;
    top: 0px;
    padding-top: 0px;
    width: 100%;
}

.modal-header {
    background: #f8f9fa;
    border-bottom: none;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    /* Larger shadow */
}

.space {
    margin: 0px;
}

.space-reporte {
    margin-left: 10px;
    margin-bottom: 20px;
}

.space-login {
    padding-right: 60px;
    padding-left: 10px;
}

.space-admin {
    padding-right: 20px;
    padding-left: -60px;
}

.space-acconts {
    padding-right: 5rem;
    padding-left: 5rem;
}

.space-modal {
    margin: 15px;
}

.container-general {
    padding-top: 30px;
    background: #f8f9fa;
    /* Color de fondo */
    box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
    /* Larger shadow */
    border-radius: 16px;
    /*height: calc(95vh - 50px);*/
    width: 100%;
    /*padding-bottom: 20px;*/
}

.space-body {
    margin-left: 40px;
    margin-right: 40px;
}

.login-shadow {
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
    padding-top: 20px;
    margin-right: 40px;
    background: #f8f9fa;
    height: calc(100vh - 150px);
    width: 100%;
    margin-top: 5px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    z-index: 0;
}

.col-img2 {
    margin-top: 0px;
    height: auto;
}

.img-login2 {
    width: 85%;
    object-fit: cover;
    margin-top: 10%;
    margin-right: 5%;
}

.col-text {
    text-align: center;
}

.Title-login {
    background: #4184EC;
    height: 60px;
    margin-left: -20px;
}

.containerProgress {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

/* Sombra a la izquierda */
.left-shadow {
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
    padding-top: 60px;
    margin-right: 40px;
    background: #f8f9fa;
    /* Color de fondo */
    border-radius: 16px;
    height: calc(90vh - 10px);
    width: 100%;
}

.left-shadow2 {
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
    padding-top: 60px;
    margin-right: 40px;
    background: #f8f9fa;
    /* Color de fondo */
    border-radius: 16px;
    height: calc(100vh - 75px);
    width: 100%;
}

.left-shadow3 {
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
    padding-top: 60px;
    margin-right: 40px;
    background: #f8f9fa;
    /* Color de fondo */
    border-radius: 16px;
    height: calc(100vh - 90px);
    width: 100%;
}

/* Sombra a la derecha */
.right-shadow {
    margin-left: 40px;
    padding-top: 60px;
    background: #f8f9fa;
    /* Color de fondo */
    box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
    /* Larger shadow */
    border-radius: 16px;
    height: calc(90vh - 10px);
}

.right-shadow2 {
    margin-left: 40px;
    padding-top: 60px;
    background: #f8f9fa;
    /* Color de fondo */
    box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
    /* Larger shadow */
    border-radius: 16px;
    height: calc(100vh - 75px);
}

.card-title-bg {
    height: 70px;
    margin-top: 50px;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-left: -12px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -12px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 10px;
    margin-bottom: 20px;
}

.card-title-bg2 {
    height: 70px;
    margin-top: 20px;
    position: relative;
    z-index: 1;
    margin-left: -12px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -12px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 10px;
    padding-left: 30px;
    margin-bottom: 20px;
}

.card-sub-title-bg {
    height: 50px;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -20px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 10px;
    margin-bottom: 20px;
}

.card-sub-title-bg2 {
    height: 50px;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -20px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 10px;
    margin-bottom: 20px;
}

.card-sub-title-bg3 {
    height: 60px;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -20px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 10px;
    margin-bottom: 20px;
}

.card-body-container-bg {
    height: 80px;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -20px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 10px;
    margin-bottom: 20px;
}

.card-body-menu-bg {
    height: 80px;
    margin-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    margin-left: -40px;
    /* Ajustado a margin-left en lugar de padding-left */
    margin-right: -40px;
    border-radius: 8px;
    background: #4184EC;
    box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15);
    /* Larger shadow */
    padding-top: 15px;
    margin-bottom: 20px;
}

.bg-seson-continer {
    background: #4184EC;
    height: 100px;
    position: relative;
    text-align: center;
    z-index: 1;
    margin-left: -45px;
    margin-right: -45px;
    border-radius: 8px;
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.15);
    margin-bottom: 40px;
    margin-top: 5px;
}

.bg-content-header {
    margin-top: 20px;
    margin-left: 80px;
    margin-right: 80px;
}

.bg-container-body {
    margin-top: -10px;
    margin-left: 0px;
    margin-right: 0px;
    height: calc(80vh - 70px);
    overflow-x: auto;
}

.bg-container-accountCover {
    margin-top: -10px;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 20px;
    padding-left: 60px;
    padding-right: 60px;
    height: calc(70vh - 20px);
}

.bg-container-body-card {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    overflow-x: auto;
    height: calc(80vh - 110px);
}

.bg-container-body-card2 {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 30px;
    overflow-x: auto;
    height: calc(80vh - 80px);
}

.bg-container-admin-card {
    margin-top: -10px;
    margin-left: 0px;
    margin-right: 0px;
    overflow-x: auto;
    height: calc(70vh - 5px);
}

.bg-btn-admin {
    text-align: right;
    align-items: end;
}

.container-file {
    padding-left: 20px;
}

.content-login {
    margin-top: 0px;
    overflow-x: auto;
    z-index: 1;
    height: calc(70vh - 80px);
}

.containerForm {
    z-index: 1;
}

.div-login {
    padding-bottom: 30px;
}

.container {
    margin: 0px;
    padding-bottom: 10px;
}

.content-cardReady {
    width: 60%;
    margin-bottom: 20px;
}

.content-cardReady2 {
    width: 35%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.container-select {
    width: 50%;
    margin-left: 25%;
}

.contentGeneral {
    margin-right: 20px;
}

.containerbody {
    margin-top: 10px;
    padding: 10px;
}

.contLogin {
    padding-left: 10px;
    margin-top: -150px;
}

.containerHome {
    padding-top: 10px;
    width: 100%;
}

.containerHome2 {
    padding-top: 10px;
}

.containerAdmin {
    padding-top: 10px;
    width: 100%;
}

.message {
    margin: 10px;
}

.title-1 {
    font-size: 24px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0px;
    padding-top: 30px;
}

.title-2 {
    font-size: 32px;
    color: #fff;
}

.title-3 {
    font-size: 20px;
    font-weight: bold;
}

.container-modal-login {
    margin: 10px;
}

.text-1 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    /*19161D*/
}

.coverText {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    /*19161D*/
}

.text-2 {
    font-size: 28px;
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    margin-left: 10px;
}

.text-3 {
    font-size: 24px;
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 10px;
}

.text-4 {
    font-size: 20px;
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    margin: 0px;
    padding-top: 15px;
    padding-left: 20px;
    margin-bottom: -10px;
}

.text-5 {
    font-size: 24px;
    text-align: center;
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 10px;
}

.text-6 {
    font-size: 18px;
    text-align: center;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10%;
    margin-bottom: 10%;
}

.text-7 {
    font-size: 20px;
    text-align: center;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10%;
    margin-bottom: 5%;
}

.text-8 {
    font-size: 18px;
    text-align: center;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 20px;
}

.text-9 {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: -10px;
}

.btn-circle {
    width: 25px;
    height: 25px;
    border-radius: 70%;
}

.space-card {
    margin-top: 5%;
    margin-bottom: 10%;
}

.title-admin {
    font-size: 20px;
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    padding: 0px;
    margin-left: 20px;
    margin-top: 15px;
}

.space-row {
    margin-left: 10px;
}

.bg-title-admin {
    margin-bottom: -10px;
}

.btn-space {
    margin-left: 10px;
}

.dateLogin {
    margin-left: 3rem;
    margin-right: 3rem;
}

.cardPolitice {
    border-color: transparent;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    border-radius: 16px;
}

.containerPolicies {
    margin-left: 20px;
    margin-right: 20px;
}

.list-group {
    border: none;
    --bs-list-group-border-color: none;
}

.custom-marker {
    list-style: none;
    padding-left: 20px;
}

.custom-marker li:before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: #000;
    border-radius: 50%;
    margin-right: 10px;
}

.item-costom {
    text-decoration: none;
    color: #000;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
}

.costom-li {
    margin-left: 20px;
    margin-bottom: 10px;
}

.contentCenter {
    margin-top: 5px;
    align-self: center;
    align-items: center;
    margin-bottom: 5px;
}

.containerCaratula {
    margin-top: 10px;
    padding: 50px;
}

.card-caratula {
    border-color: #4184EC;
    height: auto;
}

.col-Solutions {
    margin-right: 3rem;
}

.card-caratula2 {
    border-color: #4184EC;
    height: calc(80vh - 80px);
    overflow-y: auto;
}

.card-Login {
    border-color: #4184EC;
    width: 500px;
    left: 30%;
    padding-bottom: 30px;
}

.containerTrasfer {
    margin-top: 10px;
    padding: 15px;
}

.containerRowCard {
    margin-right: 0px;
    padding-left: 40px;
    padding-right: 10px;
    padding-top: 10px;
}

.containerCard {
    margin-left: 15px;
    margin-right: 20px;
    margin-bottom: 15px;
}

.containerCard2 {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
}

.containerCard3 {
    margin-left: 15px;
    margin-right: 20px;
    margin-bottom: 15px;
    padding-left: 30px;
    padding-right: 35px;
}

.containerCard4 {
    margin-left: 2px;
    margin-top: 5px;
}

.containerCardLogin {
    margin: 30px;
    padding: 20px;
    z-index: 1;
}

.contentTitle {
    height: 60px;
    margin-bottom: 0px;
}

.contentBody-bg {
    height: 80px;
    margin-bottom: 0px;
}

.cardContent {
    border-radius: 16px;
    margin: 0px;
    height: calc(90vh - 120px);
    margin-right: 10px;
}

.row>* {
    padding-left: 0px;
    padding-right: 20px;
}

.align-h {
    display: inline-block;
}

.container-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0px;
}

.datas {
    margin-bottom: 10px;
}

.valueR {
    text-align: right;
    padding-right: 5px;
}

.containerAdmin {
    width: 100%;
}

.collapse-horizontal {
    margin-right: 5px;
    height: 100%;
}

.iconMenuLateral {
    margin-left: -10px;
}

.container-menuLateral {
    border-radius: 16px;
    padding: 20px;
}

.content-title-meunu {
    margin-bottom: 20px;
}

.content-headar-menu {
    padding-left: 20px;
}

.title-menu-lateral {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #f8f9fa;
    font-weight: bold;
}

.sub-title-menu-lateral {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #f8f9fa;
}

.content-icon-menu {
    text-align: center;
    padding-top: 10px;
}

.menu-lateral-li {
    color: #4184EC;
    font-size: 30px;
    padding: 0px;
}

.menu-lateral {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: black;
    text-decoration: none;
    margin-top: -20px;
}

.menu-lateral-li:hover {
    background: #234C9020;
    font-weight: bold;
}

.selected {
    color: #4184EC;
    font-size: 32px;
    font-weight: bold;
}

.containerChart {
    padding-left: 30px;
}

.chartLine {
    width: 90%;
    height: auto;
}

.containerLinel {
    margin-bottom: 40px;
}

.titelChart {
    margin-top: 5px;
    margin-left: 10px;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-weight: bold;
}

.subtitelChart {
    margin-left: 10px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #f8f9fa;
}

.tilteAdmin {
    margin-top: 0px;
    margin-left: 10px;
    font-size: 22px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}

.col-admin {
    padding-left: 20px;
}

.row-admin {
    padding-bottom: 15px;
}

.container-admin {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 16px;
}

.content-row2 {
    padding-left: 20px;
    padding-top: 5px;
}

.content-row {
    text-align: right;
    margin-top: -10px;
}

.contenBtnAdmin {
    padding-right: 50px;
    margin-top: -5px;
}

.buttonAdmin.d-grid {
    padding-left: 10px;
    padding-right: 0px;
    margin-bottom: -20px;
}

.tab-content {
    padding-left: 10px;
    padding-right: 10px;
}

.btn-profil-admin {
    width: 40px;
    height: 40px;
    display: inline-block;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 120px;
    /* Hace que el botón sea redondo */
    padding: 7px;
    background: #f8f9fa;
    color: #4184EC;
    margin-right: 15px;
    margin-bottom: 6px;
    margin-top: 5px;
    margin-left: 20px;
}

.img-logo-microsoft {
    width: 120px;
}

.space-radio {
    margin-left: 0px;
}

.space-update {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.space-fila {
    margin-left: 20px;
}

.space-fila2 {
    margin-left: 10px;
    margin-bottom: 10px;
}

.space-label {
    margin-top: 8px;
    margin-left: 15px;
}

.add {
    text-decoration: none;
    font-weight: bolder;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
}

@media (max-width: 1400px) {
    .productsTitle {
        font-size: 24px;
    }

    .textProducts {
        font-size: 10px;
    }

    .txtNosotros,
    .txtNosotros2 {
        font-size: 10px;
    }
}

@media (max-width: 1280px) {
    .col-Solutions {
        height: 120px;
    }

    .iconSoluctions {
        width: 90px;
        height: auto;
    }

    .subTitleSoluction {
        font-size: 20px;
    }

    .txtSolutions {
        font-size: 12px;
    }

    .titleIconDes {
        font-size: 2rem;
    }

    .iconCheck {
        height: 30px;
        margin-left: 40%;
    }

    .iconStar {
        height: 25px;
    }

    .imgEdificio {
        width: 80vh;
    }

    .txtNosotros,
    .txtNosotros2 {
        font-size: 10px;
    }

    .txtFoterContact {
        font-size: 12px;
    }

    .txtHelpF {
        font-size: 12px;
    }

    .iconHelp {
        height: 26px;
    }

    .redes {
        width: 70px;
    }

    .inputFormContact {
        font-size: 12px;
    }

    .btnContact {
        font-size: 13px;
    }
}

@media (max-width: 1115px) {
    .containerFormLogin {
        width: 70%;
    }

    .containerText {
        padding-left: 4rem;
    }

    .titelLogin2 {
        font: 2em sans-serif;
    }

    .btn-light {
        font-size: 12px;
    }

    .iconSoluctions {
        width: 80px;
        height: auto;
    }

    .subTitleSoluction {
        font-size: 18px;
    }

    .txtSolutions {
        font-size: 10px;
    }

    .col-Solutions {
        height: 100px;
    }

    .titleIconDes {
        font-size: 20px;
    }

    .iconCheck {
        height: 25px;
        margin-left: 40%;
    }

    .iconStar {
        height: 20px;
    }

    .imgEdificio {
        width: 70vh;
    }

    .colDescubre {
        height: 120px;
    }

    .lineDiscover {
        width: 350px;
    }

    .bgText {
        width: 300px;
    }

    .imgGrafica {
        position: relative;
        margin-top: -15vh;
        margin-left: 50vh;
    }

    .logosPar1 {
        height: 10%;
    }

    .logosPar2 {
        height: 60%;
    }

    .txtNosotros,
    .txtNosotros2 {
        font-size: 9px;
    }

    .txtFoterContact {
        font-size: 10px;
    }

    .txtHelpF {
        font-size: 10px;
    }

    .iconHelp {
        height: 24px;
    }

    .redes {
        width: 50px;
    }

    .inputFormContact {
        font-size: 10px;
    }

    .btnContact {
        font-size: 10px;
    }
}

@media (max-width: 990px) {
    .productsTitle {
        font-size: 16px;
    }

    .textProducts {
        font-size: 10px;
    }

    .iconSoluctions {
        width: 60px;
        height: auto;
    }

    .subTitleSoluction {
        font-size: 16px;
    }

    .txtSolutions {
        font-size: 9px;
    }

    .txtNosotros,
    .txtNosotros2 {
        font-size: 8px;
    }
}

@media (max-width: 940px) {
    .productsTitle {
        font-size: 14px;
    }

    .textProducts {
        font-size: 8px;
    }

    .titleIconDes {
        font-size: 18px;
    }

    .texIconDesc {
        font-size: 10px;
    }

    .iconCheck {
        height: 20px;
        margin-left: 40%;
    }

    .iconStar {
        height: 15px;
    }

    .imgEdificio {
        width: 60vh;
        margin-top: -250px;
    }

    .colDescubre {
        height: 100px;
    }

    .lineDiscover {
        width: 300px;
        height: 155 px;
    }

    .titleDescovery {
        font-size: 30pxpx;
    }

    .subTitleSolutions {
        font-size: 24px;
    }

    .bgText {
        width: 250px;
    }

    .imgGrafica {
        position: relative;
        margin-top: -15vh;
        margin-left: 50vh;
    }
}

@media (max-width: 830px) {
    .productsTitle {
        font-size: 12px;
    }

    .textProducts {
        font-size: 6px;
    }

    .col-Solutions {
        height: 90px;
    }

    .txtSolutions {
        font-size: 8px;
    }
}

@media (max-width: 815px) {}

@media (max-width: 815px) {
    .containerHome2 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .container-fluid2 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .card {
        border-radius: 16px;
        margin-left: 10px;
        height: auto;
    }

    .cardContent {
        margin-right: 20px;
    }

    .contLogin {
        padding-left: 0px;
        padding-bottom: 10px;
        margin-top: -160px;
    }

    .containerTrasfer {
        padding: 15px;
        width: 100%;
    }

    .contentTitle {
        margin-bottom: 20px;
    }

    .containerRowCard {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
    }

    .row>* {
        padding: 0px;
        margin-right: 0px;
    }

    .file-upload-container {
        margin-bottom: 10px;
    }

    .card-body-container-bg {
        padding-top: 0px;
    }
}

@media (max-width: 767px) {
    .cardImg {
        visibility: hidden;
        width: 0px;
        height: 0px;
        margin: 0px;
        padding: 0px;
    }

    .text-Login,
    .Title-login {
        visibility: hidden;
    }

    .login-shadow {
        width: auto;
        margin-right: 4rem;
        margin-left: -1rem;
    }

}

@media (max-width: 576px) {
    .container-lg {
        margin-top: 90px;
    }

    .content-search {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }
}
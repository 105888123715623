.fixed-bottom {
    height: 70px;
    position: fixed;
    z-index: 1;
    margin-top: 20px;
    background: white;
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer {
    height: 70px;
}

.img-footer {
    background-image: url('../../assets/img/footer.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.text-footer {
    text-align: center;
    margin-top: 8%;
    font-family: 'Open Sans', sans-serif;
    color: #4184EC
}

.text-aviso {
    text-align: center;
    margin-top: 10%;
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
}

.sin {
    text-decoration: none;
}

.space-footer {
    margin-top: 16%;
}

.bgFooter {
    height: 70px;
    background: #fff;
}

.text-footer2 {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    margin-top: 30px;
    margin-left: 3rem;
}

.text-aviso2 {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    text-align: end;
    margin-top: 30px;
    margin-right: 3rem;
}

.bgIconFooter {
    text-align: center;
    padding-top: 1rem;
}

.footerLogo {
    height: 40px;
}

.spaceLogos {
    margin-left: 20%;
    margin-right: 20%;
}
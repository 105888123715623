.cardLogin {
    height: auto;
    /* 100% de la altura de la ventana */
    align-items: center;
    padding: 30px;
    z-index: 0;
}

.subTitle {
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    color: #fff;
}

.link-light {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
}

.subTitle2 {
    margin-top: 15px;
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    color: #fff;
}

.subTitle3 {
    margin-top: 15px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #2E61B4;
}

.link-primary2 {
    color: #2E61B4;
}

.text-Login {
    font-size: 52px;
    text-align: center;
    color: #4184EC;
    margin-top: 20px;
    font: 3em sans-serif;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.text-login2 {
    margin-top: 30%;
    font: 2em sans-serif;
    color: #000;
    margin-left: 50px;
    margin-right: 30px;
}

.resaltar-login {
    color: #4184EC;
    font-weight: bold;
}

.sub-title-login {
    font-size: 32px;
    color: #fff;
    text-align: center;
    padding-top: 10px;
    font-family: 'Open Sans', sans-serif;
}

.subTitle4 {
    margin-top: 5px;
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    color: #4184EC;
}

.subTitle5 {
    margin-top: 0px;
    margin-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    color: #fff;
}

.modal-title {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #4184EC;
}

.form-label-general {
    font-family: 'Open Sans', sans-serif;
    color: #262626;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
}

.form-label-login {
    color: #262626;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;
    font-size: 18px;
    padding-left: 20px;
}

.form-label-recover {
    color: #2E61B4;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;
    font-size: 18px;
    padding-left: 5px;
}

.menu-admin {
    border-radius: 16px;
    margin-left: 5px;
}

.nav-pills {
    margin-top: -4px;
}

.nav-pills .nav-link {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.nav-pills .nav-link:hover {
    color: #000;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: #234C90;
}

.form-check-label2 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 5px;
    color: #000;
    margin-bottom: 5px;
}

.form-check-label3 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 5px;
    color: #000;
    margin-bottom: 5px;
}

.form-control-login {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
}

.form-select,
option {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.contentBtn {
    margin-right: 10px;
}

.list-group-item{
    padding: 2px;
}

.autocomplete-item{
    width: 60vh;
    overflow: hidden;
    overflow-y: auto;
    height: 150px;
}

.list-group-item:hover{
 background: #4184EC20;
}

.img-fluid {
    width: 60%;
    margin-left: 15%;
    margin-top: -60px;
}

.margin-left {
    margin-left: 15px;
}

.form-label {
    color: #4184EC;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    padding-left: 5px;
}

.formLabel {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    border-radius: 10px;
}

.formLabel2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #4184EC;
    font-weight: bold;
}

.formLabel3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #4184EC;
    font-weight: bold;
}

.col-select-file {
    margin-left: 0px;
    margin-bottom: 15px;
}

.div-col-data {
    margin-right: 10px;
}

.contentDivRow {
    margin: 15px;
}

.d-grid {
    padding-right: 10px;
}

.btnGeneral {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    background: #2E61B4;
    border-radius: 16px;
    text-decoration: none;
    border: none;
    color: #fff;
    text-decoration: none;
}

.btnGeneral:hover {
    background: #2E61B495;
    color: #000;
}

.btn-login {
    margin-top: 40px;
    background: #4184EC;
    color: #fff;
    height: 50px;
    font-size: 20px;
    padding: 0px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
}

.btn-login:hover {
    background: #4184EC50;
}

.mostrarMas {
    margin-top: 15px;
    margin-bottom: 20px;
}

.containerLogin {
    position: fixed;
}

.txtForget {
    cursor: pointer;
    font-size: 16px;
    color: #262626;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    margin: 0px;
}

.txtMas {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    text-decoration: underline;
    color: #234C90;
    text-align: center;
    cursor: pointer;
}

.form-label-p {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
}

.form-label-p1 {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

.txtMas:hover {
    color: #643F99;
}

.form-check-input {
    border-color: #2E61B4;
}

.form-check-label {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #2E61B4;
}

.containerForm {
    margin-top: 0px;
}

.btnLogin {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    background: #4184EC;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    color: #fff;
    text-decoration: none;
}

.btnAceptar {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    background: green;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    color: #fff;
    text-decoration: none;
}

.btnAceptarDisabled {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    background: rgba(0, 128, 0, 0.50);
    border-radius: 10px;
    text-decoration: none;
    border: none;
    color: #fff;
    text-decoration: none;
}

.btnRechazar {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    background: red;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    color: #fff;
    text-decoration: none;
}

.btnRechazarDisabled {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    background: rgba(255, 0, 0, 0.50);
    border-radius: 10px;
    text-decoration: none;
    border: none;
    color: #fff;
    text-decoration: none;
}

.btnTraspasos {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    background: #0c67f0;
    border-radius: 10px;
    border: 1px solid #0c67f0;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-decoration: none;
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
}

.btnTraspasos:hover {
    background: #0c67f090;
}

.btnTraspasos2 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    background: #0c67f0;
    border-radius: 10px;
    border: 1px solid #0c67f0;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    text-decoration: none;
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
}

.btnTraspasos2:hover {
    background: #0c67f090;
}

.btnLogin:hover {
    background: #4184EC90;
    color: #000;
}

.btn-primary:focus {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    background: #2E61B450;
    border-radius: 16px;
}

.btn-primary2,
.btn-primary3:hover {
    background: #0d6efd;
}

.btn-success {
    background: #2E61B4;
    border-color: #2E61B4;
    border-radius: 16px;
    width: 30%;
    margin-top: 10px;
    font-family: 'Open Sans', sans-serif;
}

.btn-success2 {
    background: #2E61B4;
    border-color: #2E61B4;
    border-radius: 16px;
    width: 100%;
    margin-top: 10px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}

.btn-secondary {
    border-radius: 16px;
    font-family: 'Open Sans', sans-serif;
    width: 30%;
    margin-top: 10px;
    color: #fff;
}

.table-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.table-td:hover {
    background: #4184EC50;
}

.btn-secondary3 {
    border-radius: 16px;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    margin-top: 10px;
    background: #6C757D;
    color: #fff;
    border-radius: 10px;
    box-shadow: 8px 8px 8px rgba(1, 0, 0, 0.25);
    height: 40px;
}

.btn-secondary5 {
    border-radius: 16px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 0px;
    color: #fff;
    background: grey;
}

.btn-secondary4 {
    background: grey;
    border-color: none;
    border-radius: 16px;
    width: 100%;
    margin-top: 10px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}

.btn-secondary3:hover,
.btn-secondary4:hover,
.btn-secondary5:hover {
    background: #777777;
    color: #fff;
}

.btn-secondary2 {
    width: 100%;
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    border-color: #4184EC;
    color: #4184EC;
}

.btn-secondary2:hover {
    background: #4184EC60;
    color: #000;
}

.card-footer-login {
    margin-top: 30px;
}

.imgReg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
}

.txtLogin1 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #19161D;
}

.text-productos {
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    text-align: center;
    padding-left: 30%;
}

.text-soluciones {
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    text-align: center;
    margin-top: 10%;
    margin-left: 8rem;
    padding-right: 8rem;
}

.txtLogin2 {
    font-size: 1gpx;
    font-family: 'Open Sans', sans-serif;
    color: #643F99;
}

.txtPolicies {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #19161D;
    margin-bottom: 10px;
}

.txtPolicies2 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #19161D;
    margin: 0px;
}

.txtPolicies3 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #19161D;
    margin-bottom: 5px;
    padding-left: 20px;
    text-decoration: none;
}

.txtPolicies4 {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: #19161D;
    margin-top: 10px;
    margin-bottom: 0px;
}

.accordion-button:not(.collapsed) {
    font-weight: bold;
    background-color: #4184EC80;
    color: #234C90;
}

.resaltar {
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}

.accordion-button {
    padding: 10px;
}

.mg {
    margin-top: 20px;
}

.contentSubtitle2 {
    margin-bottom: 20px;
}

.btnForget {
    margin-top: 20px;
    text-align: end;
    width: 100%;
    /*100%*/
    margin-right: 10px;
}

.cardsInfo {
    padding-right: 10px;
    margin: 10px;
}

.labelInfo {
    width: 100%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #000;
    background: #818BBC;
    padding: 10px;
    border-radius: 16px;
}

.textInfo {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    border-radius: 16px;
    padding: 10px;
}

.subTitleModal {
    text-align: left;
    margin-left: -10px;
    margin-bottom: 20px;
}

.contentBtn2 {
    visibility: hidden;
    width: 0px;
    height: 0px;
    padding-bottom: 80px;
}

.bgLabel {
    border-radius: 10px;
    padding: 6px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    color: #234C90;
}

.contModal {
    margin-left: 20px;
    margin-right: 20px;
}

.contModal2 {
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.btnModal {
    border-radius: 16px;
    margin-left: 50px;
    font-family: 'Open Sans', sans-serif;
}

.modal-footer {
    display: block;
    align-items: center;
    text-align: center;
    border-top: none;
    background: #4184EC;
    padding: 0px;
    padding-top: 15px;
    align-items: end;
    text-align: right;
    padding-right: 20px;
    padding-bottom: 10px;
}

.btnBlock {
    margin-bottom: 10px;
}

.recoverPassword {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    --webkit-text-decoration: #fff;
    margin-top: 10px;
}

.labelModal2 {
    color: #234C90;
    font-family: 'Open Sans', sans-serif;
}

.recPass {
    color: #234C90;
    font-family: 'Open Sans', sans-serif;
}

.col-form-label-admin {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    padding-left: 5px;
    color: #307bec;
}

.form-label-admin {
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    color: #0d5fd9;
    text-align: center;
    padding-bottom: 10px;
}

.col-form-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    margin-right: 10px;
    color: #4184EC;
}

.col-form-label2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin-right: 10px;
    color: #234C90;
    font-weight: bold;
}

.col-form-label3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin-right: 10px;
    color: #000;
    text-align: left;
}

.form-label4 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #234C90;
    width: 100%;
    padding-left: 5px;
}

.col-form-label5 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    color: #000;
}

.col-form-label6 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    margin-right: 10px;
    color: #000;
    text-align: center;
}

.border {
    border-radius: 8px;
    width: 98%;
}

.border2 {
    width: 95%;
}

.content-label {
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content-label1 {
    padding-right: 10px;
}

.content-label2 {
    text-align: right;
}

.content-label3 {
    text-align: left;
    padding-right: 5px;
}

.content-label4 {
    margin-bottom: 20px;
}

.content-label5 {
    text-align: center;
}

.content-label6 {
    padding-left: 20px;
}

.row-form-text2 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.content-input {
    padding-top: 10px;
    padding-bottom: 10px;
}

.content-inputs {
    padding-top: 5px;
    padding-bottom: 5px;
}

.content-inputs2 {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
}

.content-input1 {
    padding-left: 10px;
}

.content-input3 {
    padding-left: 0px;
}

.content-input5 {
    text-align: center;
}

.content-input6 {
    padding-left: 10px;
    padding-bottom: 10px;
}

.content-input7 {
    padding-left: 10px;
    margin-bottom: 30px;
}

.txtControl {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.form-control {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.form-control:active {
    color: #2d2c2c;
}

.txtControl2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.txtControl2:active {
    color: #2d2c2c;
}

.txtControl2::placeholder {
    color: #2d2c2c;
}

.link-primary {
    text-decoration: none;
    text-align: right;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    color: rgba(255, 255, 255, var(--bs-link-opacity, 1)) !important;
    font-size: 14px;
}

.spanModal {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

.container-link {
    text-align: right;
    align-items: end;
    margin-top: 15px;
}

.container-limpiar {
    padding-top: 20px;
    margin-bottom: -30px;
}

.col-row-1 {
    background: #E5E8E8;
}

.col-div {
    border: 1px solid #D5DBDB;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
}

.col-div2 {
    border: 1px solid #D5DBDB;
    text-align: right;
    padding-top: 5px;
    padding-bottom: 5px;
}

.row-botton {
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
}

.row-form-text0 {
    padding-left: 20px;
    padding-right: 10px;
}

.row-form-text01 {
    padding-left: 20px;
    padding-right: 10px;
    margin: 0px;
}

.row-formtext {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 15px;
}

.space-detail {
    padding-top: 5px;
    padding-bottom: 20px;
}

.row-form-text {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
}

.row-form-text1 {
    margin-left: 0px;
    margin-right: 10px;
}

.row-form-text2 {
    margin-left: 20px;
    margin-right: 20px;
}

.row-form-text4 {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.row-form-text5 {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.row-form-text6 {
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 15px;
}

.row-form-text7 {
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 10px;
}

.row-form-text8 {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.row-form-text9 {
    margin-left: 5px;
    margin-top: 10px;
}

.row-form-texts {
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: -5px;
}

.row-form-texts2 {
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 10px;
}

.col-search {
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.content-select {
    margin-left: -10px;
    color: #234C90;
}

.sutitle-modal {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
}

.sutitle-modal2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
}

.tableHeader {
    visibility: visible;
}

.table-modal-data {
    color: #000;
    font-family: 'Open Sans', sans-serif;
}

.row-table-data:hover {
    background: #234C9020;
}

.file-upload-container {
    /*flex-direction: column;*/
    padding-right: 10px;
    padding-left: 25px;
}

.file-upload-button-admin {
    height: 40px;
    background-color: #234C90;
    color: #fff;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    padding-top: 6px;
    width: 100%;
}

.file-upload-button {
    height: 40px;
    background-color: #909497;
    color: #fff;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    padding-top: 6px;
    width: 100%;
}


.file-upload-button span {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    text-align: center;
}

.selected-file-text {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #234C90;
}

.contentData {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
}

.contentData2 {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
}

.dataRow {
    padding-left: 20px;
    padding-right: 10px;
}

.select-option {
    margin-right: -10px;
    color: #234C90;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
}

.labelTitle {
    color: #2E61B4;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.text-end {
    width: 100%;
}

.btn-primary {
    background: #234C90;
    border-radius: 16px;
    border: none;
    color: #fff;
    width: 100%;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
}

.form-control-plaintext {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #4184EC;
    margin-left: 0px;
}

.btn-trans {
    background: #4184EC;
    border-radius: 16px;
    border: none;
    color: #fff;
    width: 100%;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 1rem;
}

.btn-trans:hover {
    background: #4184EC70;
    color: #000;
}

.btn-download {
    background: #686C70;
    border-color: #686C70;
    border-radius: 16px;
    width: 100%;
    height: 40px;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
}

.btn-download:hover {
    background: #808080;
    color: #fff;
}

.btn-primary2 {
    background: #234C90;
    border-radius: 16px;
    border-color: #234C90;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    margin-top: 10px;
    width: 30%;
}

.btn-primary3 {
    background: #234C90;
    border-radius: 16px;
    border-color: #234C90;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    margin-top: 10px;
    width: 40%;
}

.div2 {
    margin-bottom: -20px;
}

.contentBtn {
    margin-top: 0px;
    margin-bottom: 0px;
}

.form-label2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #234C90;
}

.form-label3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #234C90;
}

.content-table-row {
    text-align: center;
    height: 30px;
    padding: 0px;
}

.form-label3 {
    border-color: gray;
}

.btnModalCerrar {
    background: #99A3A4;
    border-color: #99A3A4;
}

.btnModalCerrar:hover {
    background: #808B96;
    border-color: #808B96;
}

.label3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: none;
    color: #234C90;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
    background: #234C90;
    border-color: #234C90;
}

.btn-success2:hover,
.btn-success2:active,
.btn-success2:focus {
    background: #234C90;
    border-color: #234C90;
    color: #fff;
}

.btnExcel {
    padding: 0px;
    margin-left: -10px;
}

.copy,
.fecha {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #0d6efd;
}

.fecha {
    text-align: right;
}

.help {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    text-align: center;
    text-decoration: none;
    margin-bottom: 0px;
}

.link-table {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    text-align: center;
    text-decoration: none;
    margin-bottom: 0px;
    font-size: 16px;
}

.space-icon {
    margin-right: 5px;
}

.title-carousel {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: black;
    margin-bottom: 20px;
}

.text-carousel {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: rgb(0, 0, 0);
}

.title-header {
    font-family: 'Open Sans', sans-serif;
    color: #4184EC;
    text-align: center;
}

@media (max-width: 991px) {
    .col-form-label {
        margin: 0px;
    }

    .text-productos {
        margin-top: 10%;
        padding-left: 10%;
    }

    .imgImac {
        margin-top: 10%;
    }

    .bg-secondary-p {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .imgTerminal,
    .bg-terminal {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    .content-label {
        text-align: left;
        padding: 0px;
    }

    .content-input {
        padding: 0px;
    }

    .txtControl {
        margin: 0px;
    }

    .tableHeader {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    .container-limpiar {
        padding: 0px;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .file-upload-container {
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
        width: 100%;
    }

    .file-upload-button {
        padding-right: 10px;
    }

    .contentData {
        margin: 0px;
    }

    .container-file {
        margin-left: 10px;
        padding-right: -10px;
    }
}

@media (max-width: 900px) {
    .breadcrumb {
        margin: 0px;
        --bs-breadcrumb-item-padding-x: 5px;
    }

    .link-light {
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .contentImg {
        visibility: hidden;
        width: 0px;
    }

    .containerLogin {
        padding: 0px;
    }

    .text-productos {
        margin-top: 5%;
        padding-left: 5%;
    }

    .imgImac {
        margin-top: 15%;
    }

    .col-productos {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .text-soluciones {
        margin-left: 2rem;
        padding-right: 2rem;
    }

    .iconEmpresa {
        height: 60%;
    }

    .form-label-p {
        font-size: 16px;
    }

    .contentBtn,
    .containerBtn {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    .cardLogin {
        width: 100%;
        height: auto;
        align-items: center;
    }

    .card-caratula {
        margin-bottom: 15px;
    }

    .contentBtn2 {
        visibility: visible;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: -20px;
    }

    .inputText {
        margin-top: 10px;
        padding: 0px;
    }

    .formLabel {
        width: 100%;
        padding-right: 0px;
    }

    .col-form-label {
        margin: 0px;
    }

    .content-label {
        text-align: left;
        padding: 0px;
    }

    .content-input {
        padding: 0px;
    }

    .txtControl {
        margin: 0px;
    }

    .tableHeader {
        visibility: hidden;
        width: 0px;
        height: 0px;
    }

    .container-limpiar {
        padding: 0px;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .file-upload-container {
        padding: 0px;
    }

    .contentData {
        margin-left: 0px;
    }

    .divContainerBtn {
        visibility: hidden;
        width: 0px;
    }
}

@media (max-width: 900px) {
    .link-light {
        font-size: 14px;
    }
}

@media (max-width: 700px) {
    .bg-secondary-p {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .text-productos {
        font-size: 22px;
    }

    .text-soluciones {
        font-size: 22px;
    }
}

@media (max-width: 659px) {
    .link-light {
        font-size: 12px;
    }

    .text-productos {
        font-size: 20px;
    }

    .text-soluciones {
        font-size: 20px;
    }

    .iconEmpresa {
        height: 50%;
    }

    .bg-secondary-p {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 576px) {
    .link-light {
        font-size: 10px;
    }

    .btnForget {
        margin-top: 0px;
        margin-right: 0px;
    }

    .bg-secondary-p {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .col-img-mac {
        padding-top: 30px;
    }

    .imgImac {
        margin-top: 0px;
    }

    .form-label-p {
        font-size: 11px;
    }

    .col-content-p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 502px) {
    .link-light {
        font-size: 8px;
    }

    .bg-secondary-p {
        padding-left: 5px;
        padding-right: 15px;
    }

    .iconEmpresa {
        height: 40%;
    }
}

@media (max-width: 540px) {
    .link-light {
        font-size: 6px;
    }
}